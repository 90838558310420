import React from 'react'
import { ComparIconStyle } from './icons.style'

const ComparIcon = () => (
  <ComparIconStyle>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      version="1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path d="M445.2 109.2L402.8 66.8 256 213.6 109.2 66.8 66.8 109.2 213.6 256 66.8 402.8 109.2 445.2 256 298.4 402.8 445.2 445.2 402.8 298.4 256z" />
    </svg>
  </ComparIconStyle>
)

export { ComparIcon }
