import React from 'react'
import { string } from 'prop-types'
import { navigate } from 'gatsby'
import { Button } from '@creditas/button'
import { iconStyle } from './icons.style'

const propTypes = {
  navigateTo: string.isRequired,
}

const ArrowIcon = ({ navigateTo }) => (
  <Button onClick={() => navigate(navigateTo)} css={iconStyle}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="10" />
      <path d="M12 8L8 12 12 16" />
      <path d="M16 12L8 12" />
    </svg>
  </Button>
)

ArrowIcon.propTypes = propTypes

export { ArrowIcon }
