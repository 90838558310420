import { css, styled } from '@creditas/stylitas'

export const iconStyle = css`
  background-color: transparent;
  width: 35px;
  height: 35px;
  min-width: 1px;
  padding: 1px;
  top: 6px;
  color: black;

  :hover {
    background-color: transparent;
  }

  :focus-within {
  }
`

export const ComparIconStyle = styled.div`
  display: block;
  margin-left: 150px;
  margin-top: -65px;
`
export const EqualIconStyle = styled.div`
  display: block;
  margin-left: 325px;
  margin-top: -38px;
`
