import React, { useContext } from 'react'
import { ApplicationContext } from 'context/Application'
import Onboarding from 'pages/Onboarding'
import { node } from 'prop-types'

const propTypes = {
  children: node.isRequired,
}

function PrivateRoute({ children }) {
  const { state } = useContext(ApplicationContext)

  function isAuthenticatedByPartner() {
    return state.isAuthenticatedByPartner
  }

  return isAuthenticatedByPartner() ? <span>{children}</span> : <Onboarding />
}

PrivateRoute.propTypes = propTypes

export { PrivateRoute }
