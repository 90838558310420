/* eslint-disable react/jsx-no-literals */
import React from 'react'
import { Layout } from 'components/Layout'
import { FormCustomerInformation } from 'components/Form/FormCustomerInformation'
import { PrivateRoute } from 'components/PrivateRoute'
import { Auth } from 'components/Auth'

const Customer = () => {
  return (
    <Auth>
      <PrivateRoute>
        <Layout>
          <FormCustomerInformation />
        </Layout>
      </PrivateRoute>
    </Auth>
  )
}

export default Customer
