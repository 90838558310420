import React from 'react'
import { EqualIconStyle } from './icons.style'

const EqualIcon = () => (
  <EqualIconStyle>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      version="1.1"
      viewBox="0 0 64 64"
      xmlSpace="preserve"
    >
      <path d="M64 24.646a3 3 0 01-3 3H3a3 3 0 01-3-3V14.021a3 3 0 013-3h58a3 3 0 013 3v10.625zM64 49.979a3 3 0 01-3 3H3a3 3 0 01-3-3V39.354a3 3 0 013-3h58a3 3 0 013 3v10.625z" />
    </svg>
  </EqualIconStyle>
)

export { EqualIcon }
