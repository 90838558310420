import { styled } from '@creditas/stylitas'

export const TableContainer = styled.div`
    background-color: #fff;
    display: block;
    position: relative;
    padding: 16px;
    min-width: 1715px;
    border-radius: 4px;
    color: #4a4a4a;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)
}
`
