/* eslint-disable react/jsx-no-literals */
import React, { useContext } from 'react'
import { ApplicationContext } from 'context/Application'
import { useFormik } from 'formik'
import {
  WizardWrapper,
  WizardLabelWrapper,
} from 'components/Wrappers/wrappers.style'
import { navigate } from 'gatsby'
import { ButtonIndexClientInformation } from 'components/Button'
import { ButtonSave } from 'components/Button/ButtonSave'
import { TableContainer } from 'components/TableContainer'
import { FormClientInfomationAdress } from 'components/Form/FormClientInformationAdress'
import { FormClientInfomationBank } from 'components/Form/FormClientInformationBank'
import { FormClientInfomationDocument } from 'components/Form/FormClientInformationDocument'
import { FormClientInfomationGeral } from 'components/Form/FormClientInformationGeral'
import { ArrowIcon } from 'components/Icons'
import { H2Style, H3Style } from 'components/Title/Title.style'
import { Steps } from 'components/Wizard'
import { useToaster } from '@creditas/toaster'
import { customerInformationSchema, fieldValidation } from 'schemas'
import { useCustomer } from 'hooks/useCustomer'
import { useLoanSimulation } from 'hooks/useLoanSimuation'
import { ACCEPTABLE_ACCOUNT_TYPES } from 'constants/customerInformation'
import { unmaskCellphone } from 'src/utils/maskRemover'

const FormCustomerInformation = () => {
  const { push } = useToaster()
  const { state, dispatcher } = useContext(ApplicationContext)
  const { createCustomer, updateCustomer } = useCustomer()
  const { createProposal } = useLoanSimulation()

  async function onSubmit(values) {
    const customer = {
      ...values,
      documentNumber:
        values.documentType === 'rg' ? values.rgNumber : values.documentNumber,
      phoneNumber: unmaskCellphone(values.phoneNumber),
      customerToken: state.customerToken,
    }

    const customerResponse = state.customerId
      ? await updateCustomer(customer)
      : await createCustomer(customer)

    if (customerResponse == null) {
      push('Não foi possível enviar os dados. Tente novamente.', {
        level: 'danger',
      })
      throw new Error('Error saving data')
    } else {
      dispatcher({
        type: 'customer',
        payload: { customerId: customerResponse.id },
      })
    }

    const proposalResponse = await createProposal({
      customerId: customerResponse.id,
      customerToken: state.customerToken,
      requestedLoanAmount: state.requestedLoanAmount,
      term: state.term,
    })

    if (proposalResponse == null) {
      push('Erro criando a proposta.', {
        level: 'danger',
      })
      throw new Error('Error creating proposal')
    } else {
      push('Proposta criada com sucesso!', {
        level: 'success',
        onClose: () => navigate('/'),
      })
    }
  }

  const formikConfig = {
    initialValues: {
      fullname: state.fullname,
      document: state.document,
      birthDate: state.birthDate,
      email: state.email,
      phoneNumber: state.phoneNumber,
      motherName: '',
      rgNumber: '',
      maritalStatus: '',
      documentType: '',
      documentNumber: '',
      documentIssuingDate: '',
      documentIssuingBody: '',
      profession: '',
      birthCity: '',
      birthState: '',
      birthCountry: 'Brasil',
      income: '',
      patrimony: '',
      politicallyExposed: '',
      streetAddress: '',
      streetNumber: '',
      complement: null,
      neighborhood: '',
      zipcode: '',
      city: '',
      state: '',
      bankId: '',
      agencyNumber: '',
      accountNumber: '',
      accountType: ACCEPTABLE_ACCOUNT_TYPES[0][0],
    },
    onSubmit,
    validationSchema: customerInformationSchema,
    isInitialValid: false,
  }

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldTouched,
    setFieldError,
    isValid,
  } = useFormik(formikConfig)

  async function onBlur(event) {
    event.persist()

    const fieldName = event.target.name
    const fieldValue = event.target.value

    await fieldValidation({
      setFieldTouched,
      setFieldError,
      schema: customerInformationSchema,
      field: fieldName,
      value: fieldValue,
    })

    return handleBlur(event)
  }

  return (
    <div>
      <div>
        <ArrowIcon navigateTo="/simulacao-comercial" />
        <H2Style>
          <h2>Dados do Cliente</h2>
        </H2Style>
      </div>
      <form onSubmit={handleSubmit} style={{ padding: '30px' }}>
        <TableContainer>
          <WizardWrapper>
            <Steps current={3} />
            <WizardLabelWrapper>
              <ButtonIndexClientInformation disabled>
                consulta
              </ButtonIndexClientInformation>
              <ButtonIndexClientInformation disabled>
                simulação
              </ButtonIndexClientInformation>
              <ButtonIndexClientInformation>
                visão geral
              </ButtonIndexClientInformation>
            </WizardLabelWrapper>
          </WizardWrapper>
          <div>
            <H3Style>
              <h3>Geral</h3>
            </H3Style>
          </div>
          <div>
            <FormClientInfomationGeral
              values={values}
              errors={errors}
              touched={touched}
              handleBlur={onBlur}
              handleChange={handleChange}
            />
          </div>
          <div>
            <H3Style>
              <h3>Documento para análise</h3>
            </H3Style>
          </div>
          <div>
            <FormClientInfomationDocument
              values={values}
              errors={errors}
              touched={touched}
              handleBlur={onBlur}
              handleChange={handleChange}
            />
          </div>
          <div>
            <H3Style>
              <h3>Endereço</h3>
            </H3Style>
          </div>
          <div>
            <FormClientInfomationAdress
              values={values}
              errors={errors}
              touched={touched}
              handleBlur={onBlur}
              handleChange={handleChange}
            />
          </div>
          <div>
            <H3Style>
              <h3>Dados Bancários</h3>
            </H3Style>
          </div>
          <div>
            <FormClientInfomationBank
              values={values}
              errors={errors}
              touched={touched}
              handleBlur={onBlur}
              handleChange={handleChange}
            />
          </div>
        </TableContainer>
        <div>
          <ButtonSave
            isLoading={isSubmitting}
            disabled={isSubmitting || !isValid}
          >
            Salvar Proposta
          </ButtonSave>
        </div>
      </form>
    </div>
  )
}

export { FormCustomerInformation }
