export { DetailsIcon } from './DetailsIcon'

export { UserIcon } from './UserIcon'

export { ArrowIcon } from './ArrowIcon'

export { ComparIcon } from './ComparIcon'

export { EqualIcon } from './EqualIcon'

export { iconStyle, ComparIconStyle, EqualIconStyle } from './icons.style'
